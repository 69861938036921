import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState
} from "react";

import { echo } from "@/echo";
import Echo from "laravel-echo";

interface PropTypes {
    children: ReactNode;
}

const EchoContext = createContext<Echo<"reverb"> | null>(null);

const EchoProvider = ({ children }: PropTypes) => {
    const [echoInstance, setEchoInstance] = useState<Echo<"reverb"> | null>(
        null
    );

    useEffect(() => {
        setEchoInstance(echo);
        return echo.disconnect;
    }, []);

    return (
        <EchoContext.Provider value={echoInstance}>
            {children}
        </EchoContext.Provider>
    );
};

export const useEcho = () => {
    return useContext(EchoContext);
};

export default EchoProvider;
